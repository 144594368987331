<template>
	<footer>
		<div class="container">
			<div class="footer-top d-flex flex-wrap">
				<div class="footer-top__logo" @click="toTop(), $router.push('/')">
					<img src="./../assets/footer-logo.svg" alt="logo" class="footer-top__logo-img"/>
				</div>

				<div class="footer-top__menu d-flex flex-wrap justify-around">
					<div class="menu-column d-flex flex-column">
						<span class="menu-column__title">Меню</span>

						<nav class="menu-column__list d-flex flex-column">
							<div class="menu-column__item d-flex"
								v-for="(item, index) in categories"
								:key="index"
							>
								<a href="" class="menu-column__item-link d-flex justify-center transition"
									@click.prevent="redirectToPageWithParams('category', {
										id: item.id,
									})"
									v-bind:class="{'is-active': item.id == selectedCategory,}"
									v-if="showMenuItemsCount >= (index + 1)"
								>
									{{ item.name }}
								</a>
							</div>

							<div class="menu-column__item d-flex"
								v-if="showMenuItemsCount === 4"
								@click="showMenuItemsCount = categories.length"
							>
								<span class="transition">Все</span>
							</div>
							<div class="menu-column__item d-flex"
								v-if="showMenuItemsCount !== 4"
								@click="showMenuItemsCount = 4"
							>
								<span class="transition">Скрыть</span>
							</div>
						</nav>
					</div>

					<nav class="menu-column d-flex flex-column">
						<span class="menu-column__title">Клиенту</span>

						<nav class="menu-column__list d-flex flex-column">
							<div class="menu-column__item d-flex">
								<a href="" class="menu-column__item-link d-flex justify-center transition"
									@click.prevent="toTop(), $router.push('/delivery-payment')"
								>
									Оплата и доставка
								</a>
							</div>
							<div class="menu-column__item d-flex">
								<a href="" class="menu-column__item-link d-flex justify-center transition"
									@click.prevent="toTop(), $router.push('/faq')"
								>
									Частые вопросы
								</a>
							</div>
							<div class="menu-column__item d-flex">
								<a href="" class="menu-column__item-link d-flex justify-center transition"
									v-if="this.getToken !== null"
									@click.prevent="toTop(), $router.push('/personal-account')"
								>
									Мой профиль
								</a>
								<a href="" class="menu-column__item-link d-flex justify-center transition"
									v-else
									@click.prevent="loginModalState(true), toggleBodyClass(true)"
								>
									Войти
								</a>
							</div>
							<!--<div class="menu-column__item d-flex">
								<div class="menu-column__item-link d-flex justify-center transition"
									@click="showBonusProgramModal(), toggleBodyClass(true)"
								>
									Бонусы
								</div>
							</div>-->
						</nav>
					</nav>

					<nav class="menu-column d-flex flex-column">
						<span class="menu-column__title">Больше</span>

						<nav class="menu-column__list d-flex flex-column">
							<div class="menu-column__item d-flex">
								<a href="" class="menu-column__item-link d-flex justify-center transition"
									@click.prevent="toTop(), $router.push('/about-us')">
									О нас
								</a>
							</div>
							<!--<div class="menu-column__item d-flex">
								<a href="" class="menu-column__item-link d-flex justify-center transition"
									@click.prevent="toTop(), $router.push('/catering')">
									Кейтеринг
								</a>
							</div>-->
							<div class="menu-column__item d-flex">
								<a href="" class="menu-column__item-link d-flex justify-center transition"
									@click.prevent="toTop(), $router.push('/restaurants')">
									Наши рестораны
								</a>
							</div>
							<div class="menu-column__item d-flex">
								<a href="" class="menu-column__item-link d-flex justify-center transition"
									@click.prevent="toTop(), $router.push('/restaurant/' + deliveryAddress)"
								>
									Отзывы
								</a>
							</div>
							<div class="menu-column__item d-flex">
								<a href="" class="menu-column__item-link d-flex justify-center transition"
									@click.prevent="toTop(), $router.push('/contacts/')"
								>
									Контакты
								</a>
							</div>
						</nav>
					</nav>
				</div>

				<div class="footer-top__info d-flex flex-wrap flex-column">
					<div class="footer-top__info d-flex flex-wrap flex-column">
						<div class="footer-top__contacts d-flex justify-between items-center">
							<a href="tel:88435005070" class="footer-top__phone d-flex flex-column">
								8 843 500-50-70
								<span class="footer-top__phone-text">Бесплатно по России</span>
							</a>

							<div class="footer-top__socials d-flex">
								<a href="https://www.instagram.com/kstbcafe" class="footer-top__socials-item d-flex block-center transition" target="_blank">
									<span class="icon-instagram"></span>
								</a>
								<!--<a href="" class="footer-top__socials-item d-flex block-center transition" target="_blank">
									<span class="icon-tiktok"></span>
								</a>
								<a href="" class="footer-top__socials-item d-flex block-center transition" target="_blank">
									<span class="icon-youtube"></span>
								</a>-->
								<a href="https://vk.com/kstbcafe" class="footer-top__socials-item d-flex block-center transition" target="_blank">
									<span class="icon-vkontakte"></span>
								</a>
							</div>
						</div>

						<div class="footer-top__email">
							Для связи: <a href="mailto:kstb2017@yandex.ru" class="footer-top__email-link transition">kstb2017@yandex.ru</a>
						</div>
					</div>

					<div class="footer-top__cards d-flex items-center">
						<img src="./../assets/card1.svg" alt="card icon" class="footer-top__cards-item"/>
						<img src="./../assets/card2.svg" alt="card icon" class="footer-top__cards-item"/>
						<img src="./../assets/card3.svg" alt="card icon" class="footer-top__cards-item"/>
						<img src="./../assets/card4.svg" alt="card icon" class="footer-top__cards-item"/>
						<img src="./../assets/card5.svg" alt="card icon" class="footer-top__cards-item"/>
					</div>
				</div>
			</div>

			<div class="footer-bottom d-flex flex-wrap justify-center">
				<span class="footer-bottom__item">© {{ currentYear }}, Кафе «Кыстыбый – tatar-food» Казань</span>
				<a href="" class="footer-bottom__item footer-bottom__item--link transition" target="_blank">Политика конфиденциальности</a>
				<a href="" class="footer-bottom__item footer-bottom__item--link transition" target="_blank">Оферта</a>
				<a href="" class="footer-bottom__item footer-bottom__item--link transition" target="_blank">Карта сайта</a>
				<a href="https://promo.devmark.pro" class="footer-bottom__item d-flex items-center" target="_blank">
					Разработка и продвижение
					<img src="./../assets/devmark-logo.svg" alt="DevMark logo" class="footer-bottom__item-image"/>
				</a>
			</div>
		</div>
	</footer>

	<Transition name="modal-fade">
		<bonus-program-modal v-show="bonusProgramModal" @close-modal="bonusProgramModal = false"/>
	</Transition>

</template>

<script>
	// import { api } from '@/api';
	import CategoryMixin from '@/mixins/category.js';
	import RedirectMixin from '@/mixins/redirect.js';
	import BonusProgramModal from "./elements/BonusProgramModal";
	import { useUserStore } from '@/store/user.js';
	import { mapState } from 'pinia';

	export default {
		name: 'FooterComponent',
		mixins: [RedirectMixin, CategoryMixin],
		props: {
			showLogin: {
				type: Boolean,
				default: false,
			},
			categories: {
				type: Array,
				required: true,
			},
		},
		components: {
			BonusProgramModal,
		},
		data() {
			return {
				showMenuItemsCount: 4,
				bonusProgramModal: false,
				currentYear: new Date().getFullYear(),
			};
		},
		computed: {
			...mapState(useUserStore, [
				'deliveryAddress',
				'getToken',
			]),
		},
		methods: {
			loginModalState(val) {
				this.$emit('showLoginPageState', val);
			},
			toggleBodyClass(val) {
				document.body.classList.toggle('hidden', val);
			},
			showBonusProgramModal() {
				this.bonusProgramModal = true;
			},
			toTop() {
				window.scrollTo(0, 0);
			}
		},
	};
</script>

