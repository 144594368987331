<template>
	<div
		class="modal-overlay"
		@click="$emit('close-modal'), toggleBodyClass(false)"
	>
		<div style="margin: auto;max-width: 100%;">
			<div class="modal login-modal" @click.stop>
				<div class="modal__close d-flex block-center transition"
					@click="$emit('close-modal'), toggleBodyClass(false)"
				>
					<span class="icon-close transition"></span>
				</div>
				<div class="modal__inner d-flex flex-wrap">
					<h2 class="modal__title">Вход на сайт</h2>
					<span class="modal__text">Подарим подарок на день рождения, сохраним адрес доставки и расскажем об акциях</span>

					<div class="form">
						<div class="form-fields d-flex flex-wrap">
							<div class="form-field el-col-24">
								<label class="form-field__inner" ref="phone">
									<input
										type="tel"
										class="form-field__inner-input"
										placeholder="+7..."
										v-maska
										data-maska="+7 ### ###-##-##"
										v-model="user.phone"
										@keyup="validateForm()"
									/>
									<span class="form-field__inner-label">Номер телефона *</span>
								</label>
							</div>
						</div>

						<div class="form-fields d-flex flex-wrap"
							v-show="showRegisterForm">
							<div class="form-field el-col-24">
								<!-- add class 'is-error' -->
								<label class="form-field__inner" ref="name">
									<input
										type="text"
										class="form-field__inner-input"
										placeholder="Имя"
										v-model="user.name"
										@change="validateForm()"
									/>
									<span class="form-field__inner-label">Ваше имя *</span>
								</label>
							</div>
							<div class="form-field el-col-24">
								<label class="form-field__inner" ref="email">
									<input
										type="email"
										class="form-field__inner-input"
										placeholder="name@mail.com"
										v-model="user.email"
									/>
									<span class="form-field__inner-label">Email</span>
								</label>
							</div>
							<div class="form-field el-col-24">
								<label class="form-field__inner" ref="birthday">
									<!--<el-date-picker
										v-model="user.birthday"
										type="date"
										placeholder="дд-мм-гггг"
										:disabled-date="disabledDate"
										:shortcuts="shortcuts"
										:size="size"
										format="DD-MM-YYYY"
										value-format="DD-MM-YYYY"
										@change="validateForm()"
										locale="ru"
									/>-->
									<input
											type="tel"
											class="form-field__inner-input"
											placeholder="дд-мм-гггг"
											v-model="user.birthday"
											v-maska
											data-maska="##-##-####"
											@keyup="validateForm()"
									/>
									<label class="birthday-icon icon-calendar transition">
										<el-date-picker
											v-model="user.birthday"
											type="date"
											placeholder="дд-мм-гггг"
											:disabled-date="disabledDate"
											:shortcuts="shortcuts"
											:size="size"
											format="DD-MM-YYYY"
											value-format="DD-MM-YYYY"
											@change="validateForm()"
											locale="ru"
										/>
									</label>
									<span class="form-field__inner-label">День рождения *</span>
								</label>
							</div>
						</div>
					</div>

					<div class="modal__buttons d-flex flex-wrap">
						<button
							v-if="showRegisterForm"
							class="modal__buttons-btn modal__buttons-btn--yellow d-flex block-center transition"
							@click="register()"
						>
							Регистрация
						</button>
						<button
							v-else
							class="modal__buttons-btn modal__buttons-btn--yellow d-flex block-center transition"
							@click="getUserByPhone()"
						>
							Выслать код
						</button>

					</div>

					<label class="modal__policy noselect">
						<input
							ref="refPolicy"
							type="checkbox"
							class="modal__policy-input"
							@change="changePolicy()"
							checked
						/>
						<div class="modal__policy-inner d-flex">
							<div class="modal__policy-check d-flex block-center transition">
								<span class="icon-check"></span>
							</div>
							<span class="modal__policy-text">
								Я соглашаюсь с условиями <br />
								<a
									href=""
									class="modal__policy-link transition"
									target="_blank"
								>
									обработки персональных данных
								</a>
							</span>
						</div>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { vMaska } from 'maska';
</script>

<script>
import { api } from '@/api';
import { mapActions } from 'pinia';
import { useUserStore } from '@/store/user.js';

export default {
	name: 'LoginModal',
	data() {
		return {
			user: { phone: '', name: '', email: '', birthday: '' },
			checkLogin: true,
			showRegisterForm: false,
			emailRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
			birthdayRegex: /(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[1,2])-(19|20)\d{2}/,
		};
	},
	methods: {
		...mapActions(useUserStore, ['authorize','setUserPhone', 'setUserEmail', 'setUserName', 'setUserBirthday']),
		toggleBodyClass(val) {
			document.body.classList.toggle('hidden', val);
		},
		changePolicy() {
			this.checkLogin = !this.checkLogin;
			this.validateForm();
		},
		validateForm() {
			if(this.user.phone[3] === '8') {
				this.user.phone = this.user.phone.replace(8, '');
			}
			let valid = true;
			this.$refs.phone.classList.remove('is-error');
			this.$refs.name.classList.remove('is-error');
			this.$refs.birthday.classList.remove('is-error');
			this.$refs.email.classList.remove('is-error');
			this.$refs.refPolicy.classList.remove('is-error');

			if (this.user.phone.length != 16) {
				this.$refs.phone.classList.add('is-error');
				valid = false;
			}
			if (this.user.name.length <= 1) {
				this.$refs.name.classList.add('is-error');
				valid = false;
			}
			if (!this.user.birthday || this.user.birthday.length != 10 || !this.birthdayRegex.test(this.user.birthday)) {
				this.$refs.birthday.classList.add('is-error');
				valid = false;
			}

			if (this.user.email.length > 0 && !this.emailRegex.test(this.user.email)) {
				this.$refs.email.classList.add('is-error');
				valid = false;
			}

			if (this.checkLogin === false ) {
				this.$refs.refPolicy.classList.add('is-error');
				valid = false;
			}
			return valid;
		},
		async register() {
			if (this.validateForm()) {
				try {
					const response = await api.registration({
						phone: this.user.phone.replace(/[^+.\d]/g, ''),
						first_name: this.user.name,
						birthday: this.user.birthday,
						email: this.user.email,
					});
					const { token } = response.data;

					this.authorize(token);
					this.setUserPhone(this.user.phone.replace(/[^+.\d]/g, ''));
					this.setUserEmail(this.user.email);
					this.setUserName(this.user.name);
					this.setUserBirthday(this.user.birthday);
					console.log('register go to code!!!');
					this.$emit('close-modal');
					this.$emit('show-code-modal');
				}catch (e) {
					console.log('error register', e);
				}
			}

		},
		preparePhone() {
			this.user.phone = this.user.phone.replace(/[^+.\d]/g, '');
		},
		async getUserByPhone() {
			let valid = true;
			this.$refs.phone.classList.remove('is-error');
			this.$refs.refPolicy.classList.remove('is-error');

			if (this.user.phone.length != 16) {
				this.$refs.phone.classList.add('is-error');
				valid = false;
			}
			if (this.checkLogin === false) {
				this.$refs.refPolicy.classList.add('is-error');
				valid = false;
			}
			if (valid) {
				try {
					await api.auth({
						phone: this.user.phone.replace(/[^+.\d]/g, '')
					});

					this.setUserPhone(this.user.phone.replace(/[^+.\d]/g, ''));
					this.$emit('close-modal');
					this.$emit('show-code-modal');
				} catch (e) {
					console.log('Auth error', e);
					this.showRegisterForm = true;
				}
			}

		}
	},
};
</script>
