import { defineStore } from 'pinia';

import { api } from '@/api'
import { useUserStore } from "../store/user";


export const useOrderStore = defineStore('order', {
    state: () => {
        return {
            currentNumber: null,
            currentOrderId: null,
            items: [],
            spot: {
                city: null,
                street: null,
                building: null,
                floor: null,
                flat: null,
                doorphone: null,
                entrance: null,
                longitude: null,
                latitude: null,
                comment: null,
            },
            menuId: parseInt(process.env.VUE_APP_DEFAULT_MENU_ID),
            name: '',
            finalTime: null
        }
    },
    getters: {
        getCurrentNumber: (state) => state.currentNumber,
        getCurrentOrderId: (state) => state.currentOrderId,
        getItems: (state) => state.items,
        getItemsCount: (state) => state.items.length,
        getItem: (state) => {
            return (itemId, modifiers) => state.items.find((item) => {
                return item.product === itemId && JSON.stringify(item.modifiers) === JSON.stringify(modifiers);
            });
        },
        getMenuId: (state) => {
            return state.menuId;
        },

    },
    actions: {
        getOrderOverallProductSum(order) {
            let sum = 0;
            order.items.forEach(item => {
                // item
                if (item.cost) {
                    sum += parseFloat(item.cost);
                } else if (item.amount && item.price) {
                    sum += parseFloat(item.amount) * parseFloat(item.price);
                }
                // modifiers
                item.modifiers.forEach(modifier => {
                    if (modifier.price && modifier.amount) {
                        sum += parseFloat(modifier.price) * parseFloat(modifier.amount);
                    }
                })
            });

            return sum
        },
        setCurrentOrderId(orderId) { this.currentOrderId = orderId },
        setCurrentNumber(number) { this.currentNumber = number },
        setItem(item, price) {
            let exist = this.items.find(product => {
                return (product.product === item.id && JSON.stringify(product.modifiers) === JSON.stringify(item.modifiers));
            });
            if (exist) {
                this.plusAmount(exist.product);
            } else {
                this.items.push({
                    product: item.id,
                    amount: 1,
                    price: price,
                    modifiers: item.modifiers,
                    description: item.description,
                    image: item.image_links[0],
                    name: item.name,
                    weight: item.weight,
                    cooking_time: item.cooking_time,
                });
            }
        },
        setMenu(id) {
            this.menuId = id;
        },
        plusAmount(product) {
            product.amount++;
        },
        minusAmount(product) {
            product.amount--;

            if (product.amount === 0) {
                this.removeItem(product);
            }
        },
        removeItem(product) {
            this.items = this.items.filter(data => data !== product);
        },
        setFinalTime(time) {
            this.finalTime = time;
        },
        setComment(comment) {
            this.spot.comment = comment;
        },
        setName: (name) => { this.name = name },
        createOrder(amount) {
            // Swagger: https://kstbcafe.ru/api/swagger/#/order/order_create
            let data = {
                amount: amount,
                name: this.name,
                menu: this.getMenuId,
                spot: null,
                delivery_till_time: this.finalTime,
                delivery_cost: useUserStore().getDeliveryPrice(amount)
            };
            if (useUserStore().getDeliveryType !== 'Самовывоз' && useUserStore().getDeliveryPoint) {
                let point = useUserStore().getDeliveryPoint;
                data.spot = {
                    city: point.city,
                    street: point.street,
                    building: point.building,
                    floor: 'test',
                    flat: point.flat,
                    doorphone: point.doorphone,
                    entrance: point.entrance,
                    longitude: point.longitude,
                    latitude: point.latitude,
                    comment: this.spot.comment,
                };
            }
            data.items = [];
            this.getItems.forEach((item) => {
                let itemData = {
                    product: item.product,
                    amount: item.amount,
                    price: item.price,
                    // size: item.size,  TODO
                    size: null,
                };
                itemData.modifiers = [];
                item.modifiers.forEach((modifier) => {
                    modifier.childrenSelected.forEach((childSelectedId) => {
                        const childSelected = modifier.children.find((item) => {
                            return item.id === childSelectedId;
                        });
                        let modifierData = {
                            product: childSelected.id,
                            amount: 1,
                            price: childSelected.prices[0].current_price,
                        };
                        itemData.modifiers.push(modifierData)
                    })
                });

                data.items.push(itemData)
            });
            return api.createOrder(data);
        },

    },
    persist: true,
});


export const OrderStatus = {
    SUCCESS: 0,
    IN_PROGRESS: 1,
    ERROR: 2,
};
export const OrderPaymentStatus = {
    WAITING: 1,
    SUCCESS: 2,
    ERROR: 3,
    CANCELLED: 4,
    SYSTEM_ERROR: 5,
};
