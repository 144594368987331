import { createApp, markRaw } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import ru from 'element-plus/dist/locale/ru.mjs'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createYmaps } from 'vue-yandex-maps';
import mitt from 'mitt';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';
import { VueHeadMixin, createHead } from '@unhead/vue';

const head = createHead();

import router from "./router";

const emitter = mitt();
const app = createApp(App);
const pinia = createPinia();

app.config.globalProperties.emitter = emitter;

pinia.use(({ store }) => {
    store.router = markRaw(router)
});

pinia.use(piniaPluginPersistedstate);

app.use(router).use(ElementPlus, {locale: ru,}).use(PerfectScrollbarPlugin).use(head).mixin(VueHeadMixin).use(pinia).use(createYmaps({
    apikey: '6f7aaae5-67a3-4b07-8897-cf58d3e92906',
})).mount('#app');


import "./scss/app.scss";
