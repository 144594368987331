<template>
    <div class="modal-overlay"
         @click="$emit('close-modal'), toggleBodyClass(false)"
    >
        <div style="margin: auto;max-width: 100%;">
            <div class="modal bonus-modal" @click.stop>
                <div class="modal__close d-flex block-center transition"
                     @click="$emit('close-modal'), toggleBodyClass(false)"
                >
                    <span class="icon-close transition"></span>
                </div>
                <div class="modal__inner d-flex flex-wrap">
                    <h2 class="modal__title">Бонусная программа</h2>

                    <span class="modal__text">Сразу после регистрации на сайте и подтверждения номера телефона, <br>Вам открывается бонусный счет и уже с этого момента Вы можете копить бонусы.</span>

                    <h3 class="modal__subtitle">Как начисляются бонусы</h3>

                    <div class="bonus-modal__steps d-flex flex-wrap">
                        <div class="bonus-modal__step d-flex items-center">
                            <div class="bonus-modal__step-number d-flex block-center">1</div>
                            <span class="bonus-modal__step-text">После первого заказа <br>у вас появиться <br>бонусный счет</span>
                        </div>

                        <div class="bonus-modal__step d-flex items-center">
                            <div class="bonus-modal__step-number d-flex block-center">2</div>
                            <span class="bonus-modal__step-text">Заказывайте еду <br>на сайте и получайте <br>кэшбэк до 5% </span>
                        </div>

                        <div class="bonus-modal__step d-flex items-center">
                            <div class="bonus-modal__step-number d-flex block-center">3</div>
                            <span class="bonus-modal__step-text">Бонусы можно использовать при оформлении следующего заказа в корзине</span>
                        </div>
                    </div>
                    <button class="bonus-modal__button d-flex items-center transition"
                            @click="$emit('close-modal'), toggleBodyClass(false)"
                    >
                        Прекрасно
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'BonusProgram',
        props: {
            id: {
                required: true
            }
        },
        data() {
            return {

            };
        },
        methods: {
            toggleBodyClass(val) {
                document.body.classList.toggle('hidden', val);
            }
        },

    };
</script>
