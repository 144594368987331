export default {
    methods: {
        redirectToPage(page) {
            this.$router.push({
                name: page,
            });
        },
        redirectToPageWithParams(page, params) {
            this.$router.push({
                name: page,
                params: params
            });
        },
    },
}
