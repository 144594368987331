<template>
	<header id="header" class="transition">
		<div class="container">
			<div class="header-top d-flex flex-wrap items-center justify-between"
			>
				<div class="header-top__logo" @click="toTop(), $router.push('/')">
					<img
						src="./../assets/logo.svg"
						alt="logo"
						class="header-top__logo-img"
					/>
				</div>

				<div class="header-top__delivery d-flex items-center transition"
					@click="toggleBodyClass(true), showDeliveryModal()"
					v-if="getDeliveryType"
				>
					<span class="header-top__delivery-icon icon-direct-right"></span>
					<span class="header-top__delivery-mobile"
						v-if="getDeliveryType === 'Самовывоз' && deliveryName"
					>
						Самовывоз →
					</span>
					<span class="header-top__delivery-mobile"
						v-else-if="getDeliveryType === 'Доставка по адресу' && deliveryName"
					>
						Доставка →
					</span>
					<span class="header-top__delivery-mobile"
						v-else
					>
						Выбрать доставку
					</span>
					<span class="header-top__delivery-text">
						<span v-if="getDeliveryType === 'Самовывоз' && deliveryName">Самовывоз → </span>
						<span v-else-if="getDeliveryType === 'Доставка по адресу' && deliveryName">Доставка → </span>
						<span v-else>Выбрать доставку</span>
						{{ deliveryName }}
					</span>
				</div>

				<nav class="top-menu d-flex">
					<div class="top-menu__item">
						<a href="" class="top-menu__item-link d-flex justify-center transition"
							@click.prevent="toTop(), $router.push('/promotions')"
						>
							Акции
						</a>
					</div>
					<div class="top-menu__item">
						<a href="" class="top-menu__item-link d-flex justify-center transition"
							@click.prevent="toTop(), $router.push('/about-us')"
						>
							О нас
						</a>
					</div>
					<div class="top-menu__item">
						<a href="" class="top-menu__item-link d-flex justify-center transition"
							@click.prevent="toTop(), $router.push('/delivery-payment')"
						>
							Доставка
						</a>
					</div>
					<!-- <div class="top-menu__item">
						<a
							href=""
							class="top-menu__item-link d-flex justify-center transition"
							@click.prevent="toTop(), $router.push('/catering')"
							>Кейтеринг</a
						>
					</div> -->
					<div class="top-menu__item">
						<a href="" class="top-menu__item-link d-flex justify-center transition"
							@click.prevent="toTop(), $router.push('/restaurants')"
						>
							Наши рестораны
						</a>
					</div>
				</nav>

				<div class="top-info d-flex">
					<!--<div class="top-info__item d-flex items-center transition">
						<span class="top-info__item-icon icon-tag-b"></span>
						<span class="top-info__item-text"
							@click="showBonusProgramModal(), toggleBodyClass(true)"
						>
							Бонусы
						</span>
					</div>-->
					<div v-if="isAuthenticated"
						class="top-info__item d-flex items-center transition"
						@click="toTop(), $router.push('/personal-account')"
					>
						<span class="top-info__item-icon icon-user-b"></span>
						<span class="top-info__item-text" >Профиль</span>
					</div>
					<div v-if="!isAuthenticated"
						class="top-info__item d-flex items-center transition"
						@click="showLoginModal(), toggleBodyClass(true)"
					>
						<span class="top-info__item-icon icon-user-b"></span>
						<span class="top-info__item-text">Войти</span>
					</div>
				</div>

				<div
					class="header-burger"
					id="burger"
					@click="showHideMenu()"
					:class="{ 'is-active': mobileMenu }"
				>
					<span class="header-burger__icon"></span>
					<span class="header-burger__icon"></span>
					<span class="header-burger__icon"></span>
				</div>
			</div>

			<div class="header-bottom d-flex items-center"
				:class="{ 'is-active': mobileMenu }"
			>
				<a href="" class="header-bottom__button d-flex items-center transition noselect"
					v-if="!mobileMenu"
					@click.prevent="toTop(), $router.push('/categories')"
				>
					<span class="header-bottom__button-icon icon-list"></span>
					<span class="header-bottom__button-text">Меню</span>
				</a>
				<nav class="bottom-menu d-flex"
					v-if="!mobileMenu"
				>
					<PerfectScrollbar class="bottom-menu__inner d-flex justify-between"
						v-if="categories.length > 0"
					>
						<div class="bottom-menu__item"
							v-for="(item, index) in categories"
							:key="index"
						>
							<a href="" class="bottom-menu__item-link d-flex justify-center transition"
								@click.prevent="redirectToPageWithParams('category', {
									id: item.id,
								})"
								v-bind:class="{'is-active': item.id == selectedCategory,}"
							>
								{{ item.name }}
							</a>
						</div>
					</PerfectScrollbar>
				</nav>

				<div class="header-bottom__cart d-flex items-center transition noselect"
					v-if="!mobileMenu"
					@click="showAppCart(), toggleBodyClass(true)"
				>
					<div class="header-bottom__cart-count d-flex items-center justify-center">
						{{ getItemsCount }}
					</div>
					<span class="header-bottom__cart-text">Корзина</span>
					<span class="header-bottom__cart-icon icon-arrow-circle-right-b"></span>
				</div>
				<div class="mobile-auth d-flex justify-center" v-show="mobileMenu">
					<div v-if="isAuthenticated"
						class="mobile-auth__btn d-flex items-center transition"
						@click="toTop(), $router.push('/personal-account'), showHideMenu()"
					>
						<span class="mobile-auth__btn-icon icon-user-b"></span>
						<span class="mobile-auth__btn-text">Профиль</span>
					</div>
					<div v-if="!isAuthenticated"
						class="mobile-auth__btn d-flex items-center transition"
						@click="showLoginModal(), toggleBodyClass(true), showHideMenu()"
					>
						<span class="mobile-auth__btn-icon icon-user-b"></span>
						<span class="mobile-auth__btn-text">Войти</span>
					</div>
				</div>
				<nav class="mobile-menu d-flex flex-wrap" v-show="mobileMenu">
					<div class="mobile-menu__item d-flex">
						<a href="" class="mobile-menu__item-link d-flex items-center"
							@click.prevent="toTop(), $router.push('/promotions'), showHideMenu()"
						>
							<span class="icon-caret-right"></span>
							Акции
						</a>
					</div>
					<div class="mobile-menu__item d-flex">
						<a href="" class="mobile-menu__item-link d-flex items-center"
							@click.prevent="toTop(), $router.push('/about-us'), showHideMenu()"
						>
							<span class="icon-caret-right"></span>
							О нас
						</a>
					</div>
					<div class="mobile-menu__item d-flex">
						<a href="" class="mobile-menu__item-link d-flex items-center"
							@click.prevent="toTop(), $router.push('/delivery-payment'), showHideMenu()"
						>
							<span class="icon-caret-right"></span>
							Доставка
						</a>
					</div>
					<!--<div class="mobile-menu__item d-flex">
						<a href="" class="mobile-menu__item-link d-flex items-center"
							@click.prevent="toTop(), $router.push('/catering'), showHideMenu()"
						>
							<span class="icon-caret-right"></span>
							Кейтеринг
						</a>
					</div>-->
					<div class="mobile-menu__item d-flex">
						<a href="" class="mobile-menu__item-link d-flex items-center"
							@click.prevent="toTop(), $router.push('/restaurants'), showHideMenu()"
						>
							<span class="icon-caret-right"></span>
							Наши рестораны
						</a>
					</div>
					<!--<div class="mobile-menu__item d-flex">
						<a href="" class="mobile-menu__item-link d-flex items-center"
							@click.prevent="showBonusProgramModal(), toggleBodyClass(true)"
						>
							<span class="icon-caret-right"></span>
							Бонусы
						</a>
					</div>-->
				</nav>

				<a href="tel:88435005070" class="mobile-phone d-flex items-center"
					v-show="mobileMenu"
				>
					<div class="mobile-phone__info d-flex flex-column">
						<span class="mobile-phone__info-title">8 843 500-50-70</span>
						<span class="mobile-phone__info-text">Бесплатно по России</span>
					</div>
					<div class="mobile-phone__icon d-flex block-center">
						<span class="icon-phone"></span>
					</div>
				</a>

				<!--<div class="mobile-orders d-flex flex-wrap" v-show="mobileMenu">
					<a href="" class="mobile-orders__item d-flex items-center transition" target="_blank">
						<span class="mobile-orders__item-icon icon-whatsapp"></span>
						<span class="mobile-orders__item-text">Заказать в WhatsApp</span>
					</a>

					<a href="" class="mobile-orders__item d-flex items-center transition" target="_blank">
						<span class="mobile-orders__item-icon icon-telegram"></span>
						<span class="mobile-orders__item-text">Заказать в Telegram</span>
					</a>
				</div>-->

				<div class="mobile-socials d-flex" v-show="mobileMenu">
					<a href="https://www.instagram.com/kstbcafe" class="mobile-socials__item d-flex block-center transition" target="_blank">
						<span class="icon-instagram"></span>
					</a>
					<!--<a href="" class="mobile-socials__item d-flex block-center transition" target="_blank">
						<span class="icon-tiktok"></span>
					</a>
					<a href="" class="mobile-socials__item d-flex block-center transition" target="_blank">
						<span class="icon-youtube"></span>
					</a>-->
					<a href="https://vk.com/kstbcafe" class="mobile-socials__item d-flex block-center transition" target="_blank">
						<span class="icon-vkontakte"></span>
					</a>
				</div>
			</div>
		</div>
		<nav class="header-mobile justify-center">
			<div class="header-mobile__item d-flex justify-center">
				<a href="" class="header-mobile__item-inner d-flex flex-column items-center"
					@click.prevent="toTop(), $router.push('/categories')"
				>
					<span class="header-mobile__item-icon icon-fork-knife"></span>
					<span class="header-mobile__item-title">Меню</span>
				</a>
			</div>

			<div class="header-mobile__item d-flex justify-center">
				<a href="" class="header-mobile__item-inner d-flex flex-column items-center"
					@click.prevent="toTop(), $router.push('/promotions')"
				>
					<span class="header-mobile__item-icon icon-promotions"></span>
					<span class="header-mobile__item-title">Акции</span>
				</a>
			</div>

			<div class="header-mobile__item d-flex justify-center">
				<a href="" class="header-mobile__item-inner d-flex flex-column items-center"
					@click.prevent="toTop(), $router.push('/restaurants')"
				>
					<span class="header-mobile__item-icon icon-location"></span>
					<span class="header-mobile__item-title">Рестораны</span>
				</a>
			</div>

			<div class="header-mobile__item d-flex justify-center">
				<a href="" class="header-mobile__item-inner d-flex flex-column items-center"
					v-if="this.getToken !== null"
					@click.prevent="toTop(), $router.push('/personal-account')"
				>
					<span class="header-mobile__item-icon icon-user"></span>
					<span class="header-mobile__item-title">Профиль</span>
				</a>
				<a href="" class="header-mobile__item-inner d-flex flex-column items-center"
					v-else
					@click.prevent="loginModalState(true), toggleBodyClass(true)"
				>
					<span class="header-mobile__item-icon icon-user"></span>
					<span class="header-mobile__item-title">Войти</span>
				</a>
			</div>

			<div class="header-mobile__item d-flex justify-center"
				@click="showAppCart(), toggleBodyClass(true)"
			>
				<div class="header-mobile__item-inner d-flex flex-column items-center">
					<span class="header-mobile__item-count d-flex block-center">{{ getItemsCount }}</span>
					<span class="header-mobile__item-icon icon-cart"></span>
					<span class="header-mobile__item-title">Корзина</span>
				</div>
			</div>
		</nav>
	</header>

	<cart-element :showCart="showCart" @hide-cart="hideCart()"></cart-element>

	<Transition name="modal-fade">
		<delivery-modal
			v-if="hasOrganizations"
			v-show="showDelivery"
			@close-modal="closeDeliveryModal()"
			@show-login-modal="loginModalState(true)"
			@load-menu="getMenu()"
			ref="deliveryModal"
			@show-not-exist-products-modal="getNotExistProducts"
		/>
	</Transition>

	<Transition name="modal-fade">
		<login-modal
			v-show="showLogin"
			@close-modal="loginModalState(false)"
			@show-code-modal="showCode = true"
		/>
	</Transition>

	<Transition name="modal-fade">
		<code-modal
			v-show="showCode"
			@close-modal="showCode = false"
			@show-login-modal="loginModalState(true)"
			@show-delivery-modal="showDelivery = true"
		/>
	</Transition>

	<Transition name="modal-fade">
		<not-exist-products-modal
			:notExistProducts="notExistProducts"
			:newTypeDelivery="newTypeDelivery"
			v-show="showNotExistProductsModal"
			@close-modal="showNotExistProductsModal = false"
		/>
	</Transition>

	<Transition name="modal-fade">
		<bonus-program-modal v-show="bonusProgramModal" @close-modal="bonusProgramModal = false"/>
	</Transition>
</template>

<!--<script setup>
	import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
</script>-->

<script>
import CartElement from './elements/Cart';
import DeliveryModal from './elements/DeliveryModal';
import LoginModal from './LoginModal';
import CodeModal from './CodeModal';
import { useOrderStore } from '@/store/order.js';
import { useUserStore } from '@/store/user.js';
import { mapState } from 'pinia';
import CategoryMixin from '@/mixins/category.js';
import RedirectMixin from '@/mixins/redirect.js';
import { useOrganizationStore } from '@/store/organization.js';
import NotExistProductsModal from './elements/NotExistProductsModal';
import BonusProgramModal from "./elements/BonusProgramModal";

export default {
	name: 'HeaderComponent',
	mixins: [RedirectMixin, CategoryMixin],
	props: {
		showLogin: {
			type: Boolean,
			default: false,
		},
		menu: {
			type: Array,
			required: true,
		},
		categories: {
			type: Array,
			required: true,
		},
	},
	components: {
		CartElement,
		DeliveryModal,
		LoginModal,
		CodeModal,
		NotExistProductsModal,
		BonusProgramModal,
	},
	data() {
		return {
			mobileMenu: false,
			showCart: false,
			showDelivery: false,
			showCode: false,
			showNotExistProductsModal: false,
			notExistProducts: [],
			newTypeDelivery: '',
			bonusProgramModal: false,
		};
	},
	computed: {
		...mapState(useOrderStore, ['getItems', 'getItemsCount']),
		...mapState(useUserStore, [
			'deliveryAddress',
			'deliveryName',
			'getToken',
			'isAuthenticated',
			'getDeliveryType',
		]),
		...mapState(useOrganizationStore, ['hasOrganizations']),
	},
	created (){
		this.emitter.on('getDeliveryInfo', () => {
			this.$refs['deliveryModal'].getDeliveryInfo();
		});
		this.emitter.on('saveType', () => {
			if (this.$refs['deliveryModal'].save()) {
				this.$refs['deliveryModal'].save();
			}
		});
		this.emitter.on('sendDeliveryPointType', () => {
			if (this.$refs['deliveryModal'].sendDeliveryPoint()) {
				this.$refs['deliveryModal'].sendDeliveryPoint();
			}
		});
	},
	mounted() {
		/*if(!this.getToken) {
			this.showLoginModal();
			this.toggleBodyClass(true);
		}*/
		if(!this.deliveryName) {
			this.showDeliveryModal();
		}
	},
	methods: {
		loginModalState(val) {
			this.$emit('showLoginPageState', val);
		},
		showHideMenu() {
			this.mobileMenu = !this.mobileMenu;
		},
		showAppCart() {
			this.showCart = !this.showCart;
		},
		hideCart() {
			this.showCart = !this.showCart;
		},
		showDeliveryModal() {
			this.showDelivery = true;
			document.body.classList.add('hidden');
		},
		hideDeliveryModal() {
			this.showDelivery = false;
		},
		showLoginModal() {
			this.loginModalState(true);
			document.body.classList.add('hidden');
		},
		toggleBodyClass(val) {
			document.body.classList.toggle('hidden', val);
		},
		closeDeliveryModal() {
			this.hideDeliveryModal();
		},
		getMenu() {
			/*if (this.getToken === null) {
				this.showLoginModal();
			}*/
			this.$emit('update-categories');
		},
		getNotExistProducts(products, typeDelivery) {
			this.showNotExistProductsModal = true;
			this.notExistProducts = products;
			this.newTypeDelivery = typeDelivery;
		},
		showBonusProgramModal() {
			this.bonusProgramModal = true;
		},
		toTop() {
			window.scrollTo(0, 0);
		}
	},
};
</script>
