<template>
    <div class="cart-product d-flex items-center">
        <div class="cart-product__image d-flex block-center">
            <img :src="`${ product.image }`" alt="product image" class="cart-product__image-img" v-if="product.image">
            <img src="./../../assets/empty-image.svg" class="cart-product__image-img" alt="product image" v-else>
        </div>

        <div class="cart-product__info d-flex flex-column">
            <span class="cart-product__info-title">{{ product.name }}</span>
            <span class="cart-product__info-details d-flex flex-wrap">
                <span v-if="product.weight > 0">{{ parseFloat(product.weight * 1000) }} гр <span v-if="product.modifiers.length > 0">·&nbsp;</span></span>
                <span class="cart-product__info-modifier"
                    v-for="(item, index) in product.modifiers"
                    :key="index"
                >
                    <span v-if="item.childrenSelected[0]">{{ getChildrenName(item) }}&nbsp;</span>
                    <span v-else>{{ item.name }}</span>
                </span>
            </span>
        </div>

        <div class="cart-product__actions d-flex flex-column">
            <div class="cart-product__counter d-flex items-center">
                <div class="cart-product__counter-icon cart-product__counter-icon--minus d-flex block-center transition"
                     @click="minusCount()"
                >
                    <span class="icon-minus"></span>
                </div>
                <input type="text" class="cart-product__counter-input" v-model="amount" readonly>
                <div class="cart-product__counter-icon cart-product__counter-icon--plus d-flex block-center transition"
                     @click="addCount()"
                >
                    <span class="icon-plus"></span>
                </div>
            </div>

            <div class="cart-product__actions-bottom d-flex items-center justify-between">
                <span class="cart-product__price">{{ price }} ₽</span>
                <span class="cart-product__delete icon-trash transition" @click="remove()"></span>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapActions } from 'pinia';
    import { useOrderStore } from '@/store/order.js';
    import { mapState } from 'pinia';
    import _ from 'lodash';

    export default {
        name: 'CartProduct',
        props: {
            product: {
                type: Object,
                default: () => {},
            },
        },
        components: {

        },
        data() {
            return {
                existCartProduct: null,
            }
        },
        beforeMount() {
            this.checkCartProduct();
        },
        created(){
            this.checkCartProduct();
        },
        computed: {
            ...mapState(useOrderStore, ['getItems', 'getItem', 'getItemsCount']),
            inCart() {
                this.checkCartProduct();
                return this.existCartProduct !== undefined;
            },
            amount() {
                this.checkCartProduct();
                return (this.existCartProduct) ? this.existCartProduct.amount : 0;
            },
            price() {
                let amount = 0;
                let additionalPrice = 0;
                this.getItems.forEach(item => {
                    if(item.product === this.product.product && JSON.stringify(item.modifiers) === JSON.stringify(this.product.modifiers)) {
                        amount = item.amount;
                    }
                });
                let selectedModifiers = this.product.modifiers.filter(modifier => modifier.selected);

                selectedModifiers.forEach(modifier => {
                    modifier.children.forEach(child => {
                        if (modifier.childrenSelected.includes(child.id)) {
                            additionalPrice += parseFloat(child.prices[0].current_price);
                        }
                    });
                });
                let modifiersAmount = 0;
                if (selectedModifiers) {
                    modifiersAmount = _.sumBy(selectedModifiers, 'free_of_charge_amount');
                }
                return (parseFloat(this.product.price) + modifiersAmount + additionalPrice) * amount;
            }
        },
        methods: {
            checkCartProduct() {
                this.existCartProduct = this.getItem(this.product.product, this.product.modifiers);
            },
            ...mapActions(useOrderStore, ['setItem', 'plusAmount', 'minusAmount', 'removeItem']),
            minusCount() {
                this.minusAmount(this.existCartProduct);
                this.checkCartProduct();
            },
            addCount() {
                this.plusAmount(this.existCartProduct);
            },
            remove() {
                this.removeItem(this.existCartProduct);
                this.checkCartProduct();
            },
            getChildrenName(item) {
                let children = item.children.filter(function (child) {
                    return item.childrenSelected.includes(child.id);
                });
                return children.map(child => child.name).join(', ');
            }
        },
    }
</script>
