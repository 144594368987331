<template>
    <div class="product d-flex flex-wrap justify-center">
        <div class="product__inner d-flex flex-column">
            <div class="product__image d-flex block-center">
                <div class="product__status product__status--hit d-flex items-center">Хит</div><!-- hit, new -->
                <img src="./../../assets/product-image1.png" alt="product image" class="product__image-img transition">
            </div>
            <div class="product__info d-flex flex-column items-center">
                <span class="product__info-price">165 ₽</span>
                <span class="product__info-title">Комбо #1 · Чип-чеби</span>
            </div>
        </div>
        <button class="product__button d-flex block-center transition" @click="addToCart()" v-show="!showCounter">Добавить</button>

        <div class="product__counter d-flex items-center" v-show="showCounter">
            <div class="product__counter-icon product__counter-icon--minus d-flex block-center transition"
                 @click="minusCount()"
            >
                <span class="icon-minus"></span>
            </div>
            <input type="text" class="product__counter-input" v-model="count">
            <div class="product__counter-icon product__counter-icon--plus d-flex block-center transition"
                 @click="addCount()"
            >
                <span class="icon-plus"></span>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ProductApp',
        components: {

        },
        data() {
            return {
                count: 1,
                showCounter: false,
            }
        },
        methods: {
            minusCount() {
                if(this.count > 1) {
                    this.count -= 1;
                }else if(this.count == 1) {
                    this.showCounter = false;
                }
            },
            addCount() {
                this.count += 1;
            },
            addToCart() {
                this.showCounter = true;
            },
        },
    }
</script>
