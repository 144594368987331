<template>
    <swiper
            :effect="fade"
            :modules="modules"
            :slides-per-view="2"
            :space-between="10"
            navigation
            :grabCursor="true"
            :loop="true"
            :centeredSlides="true"
            :breakpoints="{
                '320': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                '400': {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                '576': {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                '768': {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                '992': {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                '1200': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
            }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            class="cart-slider"
    >
        <swiper-slide class="slider-slide">
            <product-add />
        </swiper-slide>

        <swiper-slide class="slider-slide">
            <product-add />
        </swiper-slide>

        <swiper-slide class="slider-slide">
            <product-add />
        </swiper-slide>

        <swiper-slide class="slider-slide">
            <product-add />
        </swiper-slide>

        <swiper-slide class="slider-slide">
            <product-add />
        </swiper-slide>
    </swiper>
</template>
<script>
    import ProductAdd from './../elements/ProductAdd';

    // Import Swiper Vue.js components
    import { EffectFade, Navigation, Pagination } from 'swiper/modules';
    import { Swiper, SwiperSlide } from 'swiper/vue';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/effect-fade';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';

    export default {
        components: {
            ProductAdd,
            Swiper,
            SwiperSlide,
        },
        setup() {
            const onSwiper = (swiper) => {
                console.log(swiper);
            };
            const onSlideChange = () => {
                console.log('slide change');
            };
            return {
                onSwiper,
                onSlideChange,
                modules: [EffectFade, Navigation, Pagination],
            };
        },
    };
</script>