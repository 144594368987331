import { api } from '@/api.js';
import { mapState } from 'pinia';
import { useOrderStore } from "../store/order";
import { useUserStore } from "../store/user";
import { useOrganizationStore } from '@/store/organization.js';
import { mapActions } from 'pinia';

export default {
    data() {
        return {
            menu: [],
            categories: [],
            category: [],
            organizations: [],
        }
    },
    computed: {
        ...mapState(useOrderStore, ['getMenuId']),
        ...mapState(useUserStore, ['getDeliveryAddress', 'getDeliveryType']),
        ...mapState(useOrganizationStore, ['getOrganizations']),
        selectedCategory() {
            return this.$route.params.id;
        },
    },
    methods: {
        ...mapActions(useUserStore, ['setDeliveryAddress', 'setDeliveryName']),
        async fetchMenu() {
            console.log('getMenuId -- ', this.getMenuId);
            try {
                console.log('getMenuItem categotyjs', this.getMenuId);
                const response = await api.getMenuItem(this.getMenuId, this.getDeliveryType);
                this.categories = this.prepareCategories(response.data.categories);
            } catch (error) {
                console.error('Ошибка получения списка елементов:', error);
            }
        },
        prepareCategories(data) {
            return data.filter(category => {
                return category.products.length > 0;
            })
        },
    },
}
