import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        name: "main-page",
        component: () => import("@/components/pages/Main.vue"),
    },
    {
        path: "/categories",
        name: "categories",
        component: () => import("@/components/pages/CategoriesPage.vue"),
    },
    {
        path: "/category/:id",
        name: "category",
        component: () => import("@/components/pages/CategoryPage.vue"),
    },
    {
        path: "/delivery-order",
        name: "delivery-order",
        component: () => import("@/components/pages/DeliveryOrder.vue"),
    },
    {
        path: "/order-accepted",
        name: "order-accepted",
        component: () => import("@/components/pages/OrderAccepted.vue"),
    },
    {
        path: "/about-us",
        name: "about-us",
        component: () => import("@/components/pages/AboutUs.vue"),
    },
    {
        path: "/delivery-payment",
        name: "delivery-payment",
        component: () => import("@/components/pages/DeliveryPayment.vue"),
    },
    {
        path: "/catering",
        name: "catering",
        component: () => import("@/components/pages/CateringPage.vue"),
    },
    {
        path: "/restaurants",
        name: "restaurants",
        component: () => import("@/components/pages/OurRestaurants.vue"),
    },
    {
        path: "/restaurant/:id",
        name: "restaurant",
        component: () => import("@/components/pages/RestaurantPage.vue"),
    },
    {
        path: "/personal-account",
        name: "personal-account",
        component: () => import("@/components/pages/PersonalAccount.vue"),
    },
    {
        path: "/promotions",
        name: "promotions",
        component: () => import("@/components/pages/PromotionsApp.vue"),
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import("@/components/pages/FaqPage.vue"),
        // meta: { scrollToTop: true }
    },
    {
        path: "/contacts",
        name: "contacts",
        component: () => import("@/components/pages/ContactsPage.vue"),
        // meta: { scrollToTop: true }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,

    /*scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }*/
});

/*router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register'];
    const authRequired = !publicPages.includes(to.path);
    const userStore = useAuthStore();

    if (authRequired && !userStore.token) {
        next('/login');
    } else {
        next();
    }
});*/

export default router;
