import { defineStore } from 'pinia';
// import { api } from '@/api';

export const useOrganizationStore = defineStore('organization', {
    state: () => {
        return {
            list: []
        }
    },
    getters: {
        getOrganizations: (state) => state.list,
        hasOrganizations: (state) => state.list.length > 0,
    },
    actions: {
        setOrganizations(data) {
            this.list = data;
        },
    },
    persist: true,
});
